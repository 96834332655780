import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGraduationCap,
  faBriefcase,
  faCalendarAlt,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'

import './cvCloud.css'

const SecondEdu = ({ secondEdu }) => {
  const renderContent = () => {
    if (!secondEdu || secondEdu.length < 1) return null
    return (
      <>
        <div className="cvCloudRightBitBed">
          <div className="cvCloudRightHeading">Secondary education</div>
          {secondEdu.map((sec) => {
            return (
              <div
                className={
                  secondEdu.length > 1
                    ? 'cvCloudRightFieldsTwoBed'
                    : 'cvCloudRightFieldsBed'
                }
                key={sec._id}
              >
                <div className="cvCloudRightFieldBed">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="cvCloudRightFieldIcon"
                  />
                  <div className="cvCloudRightFieldText">{sec.schoolName}</div>
                </div>
                {!sec.startDate || sec.startDate.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="cvCloudRightFieldText">
                      {sec.startDate}
                      {!sec.endDate ? null : ` - ${sec.endDate}`}
                    </div>
                  </div>
                )}
                {!sec.subjects || sec.subjects.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="subjectsBed">
                      {sec.subjects.map((subject) => {
                        return (
                          <div className="subjectText" key={subject.subject}>
                            {subject.subject}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}

                {!sec.additionalInfo || sec.additionalInfo.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="cvCloudRightFieldText">
                      {sec.additionalInfo}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <hr className="cvCloudRightHr" />
      </>
    )
  }

  return renderContent()
}

export default SecondEdu
