import React, { useContext, useEffect, useState } from 'react'

import LoaderFullScreen from '../../bits/LoaderFullScreen'
import MenuModal from '../../modals/menuModal/MenuModal'
import MediaModal from '../../modals/mediaModal/MediaModal'
import CVCloud from '../../cvTemplates/cvCloud/CVCloud'
import { Context as UniversalContext } from '../../../context/UniversalContext'
import './managmaneView.css'

const ManagmentView = (props) => {
  const [pin, setPin] = useState('')
  const [cvArrayPosition, setCvArrayPosition] = useState(0)
  const [cvId, setCvId] = useState('')

  const {
    state: { loading, curriculumVitae, curriculumVitaeAll, apiMessage },
    fetchCV_ToView,
    fetchCV_ToViewAll,
  } = useContext(UniversalContext)

  console.log(curriculumVitaeAll)

  useEffect(() => {
    if (curriculumVitaeAll) {
      setCvId(curriculumVitaeAll[cvArrayPosition])
    }
  }, [curriculumVitaeAll, cvArrayPosition])

  useEffect(() => {
    if (curriculumVitaeAll) {
      fetchCV_ToView({ id: cvId })
    }
  }, [cvId])

  const cvPicker = () => {
    return (
      <div className="managementViewPinNavButtonBed">
        <button
          className="managementViewPinNavButton"
          onClick={() => setCvArrayPosition(cvArrayPosition - 1)}
        >
          back
        </button>
        -----
        <button
          className="managementViewPinNavButton"
          onClick={() => setCvArrayPosition(cvArrayPosition + 1)}
        >
          next
        </button>
      </div>
    )
  }

  const renderPinInput = () => {
    return (
      <div className="managementViewPinInputBed">
        <div className="managementViewPinInputContainer">
          <input
            className="managementViewPinInput"
            placeholder="who dis...?"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          />
          <button
            className="managementViewPinInputButton"
            onClick={() => fetchCV_ToViewAll({ pin })}
          >
            hit me
          </button>
        </div>
      </div>
    )
  }

  const renderCvViewer = () => {
    if (!curriculumVitae) return null
    const {
      _photo,
      _attribute,
      _contactInfo,
      _certificate,
      _employHistory,
      _experience,
      _firstImpression,
      _interest,
      _language,
      _personalInfo,
      _personalSummary,
      _reference,
      _secondEdu,
      _skill,
      _tertEdu,
    } = curriculumVitae[0]
    return (
      <CVCloud
        photo={_photo}
        attribute={_attribute}
        contactInfo={_contactInfo[0]}
        certificate={_certificate}
        employHistory={_employHistory}
        experience={_experience}
        firstImpression={_firstImpression}
        interest={_interest}
        language={_language}
        personalInfo={_personalInfo[0]}
        personalSummary={_personalSummary[0]}
        reference={_reference}
        secondEdu={_secondEdu}
        skill={_skill}
        tertEdu={_tertEdu}
      />
    )
  }

  const renderContent = () => {
    if (loading) return <LoaderFullScreen />
    if (apiMessage) return <div className="managementViewApiMessage">;-)</div>
    if (!curriculumVitaeAll) return renderPinInput()
    return (
      <>
        {cvPicker()}
        {renderCvViewer()}
      </>
    )
  }

  return renderContent()
}

export default ManagmentView
