import React from 'react'

import step1 from '../../../assets/images/exit_step1.png'
import step2 from '../../../assets/images/exit_step2.png'
import step3 from '../../../assets/images/exit_step3.png'
import './termsAndConditions.css'

const ExitingInstructions = () => {
  return (
    <div className="termsBed">
      <div className="termsContainer">
        <div className="exitingIntructionHeading">
          CV Cloud Exiting Instructions
        </div>
        <div className="exitingIntructionSubHeading">
          How to permanently delete my CV Cloud data and close my account?
        </div>
        <div className="exitingIntructionWarningText">
          Note: Deleting your account is irreversible and all your data will be
          lost permenetly!
        </div>
        <div className="exitingIntruction">
          To permanently delete your account:
        </div>
        <div className="exitingIntruction">
          <div className="exitingIntructionTextStepText">
            <div className="exitingIntructionTextStepLabel">Step 1</div>
            <>Open CV Cloud app and press menu</>
          </div>
          <div>
            <img
              src={step1}
              alt="step1"
              className="exitingIntructionTextStepImage"
            />
          </div>
        </div>
        <div className="exitingIntruction">
          <div className="exitingIntructionTextStepText">
            <div className="exitingIntructionTextStepLabel">Step 2</div>
            <>Select option "delete my account"</>
          </div>
          <div>
            <img
              src={step2}
              alt="step3"
              className="exitingIntructionTextStepImage"
            />
          </div>
        </div>
        <div className="exitingIntruction">
          <div className="exitingIntructionTextStepText">
            <div className="exitingIntructionTextStepLabel">Step 3</div>
            <>Select "Yes"</>
          </div>
          <div>
            <img
              src={step3}
              alt="step3"
              className="exitingIntructionTextStepImage"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExitingInstructions
