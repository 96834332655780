import React from 'react'
import { IoLogoApple, IoLogoAndroid } from 'react-icons/io'

import landingLogo from '../../../assets/images/logo-h65.png'
import shovel from '../../../assets/images/shovel-w100.png'
import appgallery_logo from '../../../assets/images/appgallerty_logo_w200.png'
import './landing.css'

const Landing = () => {
  const renderContent = () => {
    return (
      <div className="landingBed">
        <div className="landingContainer">
          <div className="landingLogoBed">
            <div className="landingLogobed">
              <img
                className="landingLogo"
                src={landingLogo}
                alt="cvCloud-logo"
              />
            </div>
            <div className="landingSlogan">
              Curriculum Vitae creating and sharing simplified
            </div>
            <div className="landingMessageBed">
              <div className="landingMessageOne">
                Web application in beta, some features may not work as expected.
                <br />
                <div className="linkToWebAppBed">
                  <div className="linkToWebAppContainer">
                    <a
                      className="linkToWebApp"
                      href="https://cvcloud.netlify.app/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Go to app
                    </a>
                  </div>
                </div>
                <div className="landingLogobed">
                  <img
                    className="landingShovel"
                    src={shovel}
                    alt="work-in-progress"
                  />
                </div>
              </div>
              <div className="landingMessageTwo">
                Mobile application available at Apple App Store, Google Play
                Store and Huawei App Gallery
                <div className="landingAppStoresBed">
                  <IoLogoApple className="landingAppStoreLogo" />
                  <IoLogoAndroid className="landingAppStoreLogo" />
                  <img
                    className="landingAppgalleryLogo"
                    src={appgallery_logo}
                    alt="appgallery_logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return renderContent()
}

export default Landing
