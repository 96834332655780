import React, { useState, useEffect } from 'react'

import './cvCloud.css'

const Photo = ({ photo }) => {
  const [assignedPhoto, setAssignedPhoto] = useState(null)

  useEffect(() => {
    runPhotoName()
  }, [])

  const assignedPhotoArray = photo.filter((ph) => {
    return ph.assigned === true
  })

  const runPhotoName = () => {
    if (!photo || photo.length < 1) return null
    setAssignedPhoto(assignedPhotoArray[0].photoUrl)
  }

  const renderContent = () => {
    if (!photo || photo.length < 1) return null
    return (
      <div className="cvCloudPhotoBed">
        <img className="cvCloudPhoto" src={assignedPhoto} alt="portrait" />
      </div>
    )
  }

  return renderContent()
}

export default Photo
