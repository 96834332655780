import React, { useState, useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { Context as AuthContext } from '../../../../context/AuthContext'
import { Context as ModalContext } from '../../../../context/ModalContext'
import LoaderFullScreen from '../../../bits/LoaderFullScreen'
import NotificationModal from '../../../modals/notificationModal/NotificationModal'
import loginLogo from '../../../../assets/images/logo-h65.png'
import history from '../../../../history'
import './login.css'

const Login = () => {
  const [emailLoginInputShow, setEmailLoginInputShow] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorEmail, setErrorEmail] = useState(null)
  const [errorPassword, setErrorPassword] = useState(null)

  const {
    state: { loading, error },
    login,
    clearError,
  } = useContext(AuthContext)

  const { setNotificationModalVisible } = useContext(ModalContext)

  useEffect(() => {
    if (error) {
      if (error.email) setErrorEmail(error.email)
      if (error.password) setErrorPassword(error.password)
      if (error.notVerified) setNotificationModalVisible(true)
    }
  }, [error])

  const clearErrors = () => {
    clearError()
    setErrorEmail(null)
    setErrorPassword(null)
  }

  const emailLoginInput = () => {
    if (!emailLoginInputShow) return null
    return (
      <div className="loginBed">
        <div className="loginBackArrowBed">
          <div className="loginBackArrowContainer">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="loginBackArow"
              onClick={() => setEmailLoginInputShow(false)}
            />
          </div>
        </div>
        <div className="loginContainer">
          <div className="loginLogobed">
            <img className="loginLogo" src={loginLogo} alt="cvCloud-logo" />
          </div>
          <div className="loginHeading">Login with email</div>
          <div className="loginEmailInputsBed">
            <div className="loginEmailInputsContainer">
              <input
                className={
                  !errorEmail ? 'loginEmailInput' : 'loginEmailInputError'
                }
                type="email"
                placeholder="Email"
                value={email}
                onFocus={() => clearErrors()}
                onChange={(e) => setEmail(e.target.value)}
              />
              {!errorEmail ? null : (
                <div className="loginErrorText">{errorEmail}</div>
              )}
              <input
                className={
                  !errorPassword ? 'loginEmailInput' : 'loginEmailInputError'
                }
                type="password"
                placeholder="Password"
                value={password}
                onFocus={() => clearErrors()}
                onChange={(e) => setPassword(e.target.value)}
              />
              {!errorPassword ? null : (
                <div className="loginErrorText">{errorPassword}</div>
              )}
            </div>
          </div>
          <div
            className="loginButtonBed"
            onClick={() =>
              login({
                email,
                password,
                platform: 'web',
              })
            }
          >
            <div className="loginButton">Sign in</div>
          </div>
          <div
            className="loginLink"
            onClick={() => console.log(`forgot password`)}
          >
            Forgot your password? Reset password here.
          </div>
          <div className="loginLink" onClick={() => history.push('/register')}>
            Don't have an account? Sign up here
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (loading) return <LoaderFullScreen />
    if (emailLoginInputShow) return emailLoginInput()
    return (
      <div className="loginBed">
        <div className="loginContainer">
          <div className="loginLogobed">
            <img className="loginLogo" src={loginLogo} alt="cvCloud-logo" />
          </div>
          <div className="loginHeading">Login here</div>
          <div className="loginButtonBed">
            <div
              className="loginGoogleButton"
              onClick={() => console.log('Google')}
            >
              <FontAwesomeIcon icon={faGoogle} className="loginButtonIcon" />
              <div className="loginButtonText">Google</div>
            </div>
          </div>
          <div className="loginButtonBed">
            <div
              className="loginFacebookButton"
              onClick={() => console.log('Facebook')}
            >
              <FontAwesomeIcon icon={faFacebookF} className="loginButtonIcon" />
              <div className="loginButtonText">Facebook</div>
            </div>
          </div>
          <div className="loginButtonBed">
            <div
              className="loginEmailButton"
              onClick={() => setEmailLoginInputShow(true)}
            >
              <FontAwesomeIcon icon={faEnvelope} className="loginButtonIcon" />
              <div className="loginButtonText">Email</div>
            </div>
          </div>
          <div className="loginLink" onClick={() => history.push('/register')}>
            Don't have an account? Sign up here
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <NotificationModal bit="notVerified" dismissRoute="/" />
      {renderContent()}
    </>
  )
}

export default Login
