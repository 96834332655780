import React from 'react'

import './termsAndConditions.css'

const PrivacyPolicy = () => {
  return (
    <div className="termsBed">
      <div className="termsContainer">
        <div className="termsHeading">CV CLOUD</div>
        <div className="termsHeading">
          MOBILE APPLICATION / WEBSITE PRIVACY POLICY
        </div>
        <div className="termsText">1. ABOUT THIS POLICY</div>
        <div className="termsText">
          1.1 This Policy describes how CV Cloud Processes information we
          collect and/or receive from you.
        </div>
        <div className="termsText">
          1.2 CV Cloud is a sole proprietorship; with its primary place of
          business at 3 Halitestreet, Carletonville.
        </div>
        <div className="termsText">
          1.3 CV Cloud is a “Responsible Party”. This means that we are
          responsible for deciding how we hold and use Personal Information
          about you. We are required under data protection legislation to notify
          you of the information contained in this Privacy Policy.
        </div>
        <div className="termsText">
          1.4 This Policy applies to all Data Subjects who visit this Website
          and all Data Subjects who CV Cloud Processes their Personal
          Information.
        </div>
        <div className="termsText">2. DEFINITIONS</div>
        <div className="termsText">2.1. For purposes of this Policy:</div>
        <div className="termsText">
          2.1.1 “CV Cloud”, “Us” or “We” means CV Cloud;
        </div>
        <div className="termsText">
          2.1.2 “Data Subject” or “You” means any person to whom the specific
          Personal Information relates, as contemplated in POPIA;
        </div>
        <div className="termsText">
          2.1.3 “IO” means the Information Officer of CV Cloud;
        </div>
        <div className="termsText">
          2.1.4 “Personal Information” means information relating to an
          identifiable, living, natural person, and (where applicable) an
          identifiable, existing juristic person, including the name, race,
          gender, marital status, address and identifying number of a person,
          symbol, e-mail address, physical address, telephone number, location
          information, online identifier or other particular assignment to the
          person;
        </div>
        <div className="termsText">
          2.1.5 “Policy” or “Privacy Policy” means this Website Privacy Policy;
        </div>
        <div className="termsText">
          2.1.6 “POPIA” means the Protection of Personal Information, of 2013;
        </div>
        <div className="termsText">
          2.1.7 “Processing” or “Process” means any activity that involves the
          use of Personal Information. It includes any operation or activity or
          any set of operations, whether or not by automatic means, concerning
          Personal Information, including:
        </div>
        <div className="termsText">
          2.1.7.1 the collection, receipt, recording, organisation, collation,
          storage, updating or modification, retrieval, alteration, consultation
          or use;
        </div>
        <div className="termsText">
          2.1.7.2 dissemination by means of transmission, distribution or making
          available in any other form; or
        </div>
        <div className="termsText">
          2.1.7.3 merging, linking, as well as restriction, degradation, erasure
          or destruction of information;
        </div>
        <div className="termsText">
          2.1.8 “Special personal information” means personal information
          concerning the religious or philosophical beliefs, race or ethnic
          origin, trade union membership, political persuasion, health or sex
          life or biometric information of a data subject; or the criminal
          behaviour of a data subject to the extent that such information
          relates to the alleged commission by a data subject of any offence; or
          any proceedings in respect of any offence allegedly committed by a
          data subject or the disposal of such proceedings;
        </div>
        <div className="termsText">
          2.1.9 “Social Media Platforms” means platforms such as Facebook,
          LinkedIn, Twitter, Pinterest, YouTube, Instagram, WeChat, WhatsApp,
          TikTok, blogs and all other similar Social Media or communication
          platforms; and
        </div>
        <div className="termsText">
          2.1.10 “Website” means CV Cloud’s website and mobile application
          www.cvcloud.app
        </div>
        <div className="termsText">3. INFORMATION WE COLLECT AND RECEIVE</div>
        <div className="termsText">
          We collect and receive information about you in the following ways:
        </div>
        <div className="termsText">3.1 Information you give us</div>
        <div className="termsText">
          This includes any information that you provide to us directly:
        </div>
        <div className="termsText">
          3.1.1 when you sign-up to use our services;
        </div>
        <div className="termsText">
          3.1.2 by filling in forms on our websites, or those provided to you;
          or
        </div>
        <div className="termsText">
          3.1.3 when you contact us or we contact you and you provide
          information directly to us.
        </div>
        <div className="termsText">3.2 Personal Information we collect.</div>
        <div className="termsText">
          3.2.1 When you register to use our services, you will be required to
          provide us with the following information, your:
        </div>
        <div className="termsText">3.2.1.1 name and surname; and</div>
        <div className="termsText">3.2.1.2 email address.</div>
        <div className="termsText">
          3.2.2 We will also collect the Personal Information which you upload
          onto your profile when building your CV, this will include (without
          limitation):
        </div>
        <div className="termsText">3.2.2.1 contact details;</div>
        <div className="termsText">3.2.2.2 physical address;</div>
        <div className="termsText">3.2.2.3 education and job history;</div>
        <div className="termsText">3.2.2.4 race information; and/or</div>
        <div className="termsText">3.2.2.5 health information.</div>
        <div className="termsText">
          3.2.3 By submitting the above information to us when preparing your CV
          you hereby consent to us collecting and otherwise Processing such
          Personal Information and Special Personal Information.
        </div>
        <div className="termsText">
          3.3 Information we collect or receive when you use our Website.
        </div>
        <div className="termsText">
          We collect information when you use Websites by using cookies, web
          beacons and other technologies. Depending on how you access and use
          websites, we may receive:
        </div>
        <div className="termsText">3.3.1 log information;</div>
        <div className="termsText">
          3.3.2 information we infer about you based on your interaction with
          products and services;
        </div>
        <div className="termsText">
          3.3.3 device information (for example the type of device you're using,
          how you access platforms, your browser or operating system and your
          Internet Protocol ("IP") address); and
        </div>
        <div className="termsText">3.3.4 location information.</div>
        <div className="termsText">
          4. HOW WE USE THE INFORMATION WE COLLECT AND RECEIVE.
        </div>
        <div className="termsText">
          We use the information we collect and receive for the following
          general purposes:
        </div>
        <div className="termsText">
          4.1 to provide you with information, products or services you request
          from us;
        </div>
        <div className="termsText">
          4.2 in order to refer you to an appropriate third-party service
          provider;
        </div>
        <div className="termsText">4.3 to communicate with you;</div>
        <div className="termsText">4.4 to provide you with support; and</div>
        <div className="termsText">
          4.5 to provide effective advertising (for example to be provide you
          with news, special offers and general information about other goods,
          services and events which we offer, that are similar to those that you
          have already enquired about).
        </div>
        <div className="termsText">
          5 HOW WE SHARE THE INFORMATION WE COLLECT AND RECEIVE.
        </div>
        <div className="termsText">
          5.1 We don’t sell your Personal Information to third parties for their
          marketing purposes.
        </div>
        <div className="termsText">5.2 We may share information with:</div>
        <div className="termsText">
          5.2.1 our affiliates, in other words, other companies in our group;
        </div>
        <div className="termsText">
          5.2.2 we may disclose your Personal Information to a limited number of
          our employees and third party service providers (other than those who
          we refer you to), who we assist you to interact with;
        </div>
        <div className="termsText">
          5.2.3 other parties in response to legal process or when necessary to
          conduct or protect our legal rights;
        </div>
        <div className="termsText">
          5.2.4 companies that provide services to us. Companies that provide
          services to us or act on our behalf may have access to information
          about you. These companies are limited in their ability to use
          information they receive in the course of providing services to us or
          you; and
        </div>
        <div className="termsText">
          5.2.5 third-parties where you provide consent. In some cases,
          third-parties (often advertisers) may wish to attain information about
          you in order to promote their products to you, or for whatever other
          reason. We may share information with third-parties where you provide
          consent in the form of an explicit opt-in. Before we ask you to
          opt-in, we will endeavour to provide you with a clear description of
          what data would be shared with the third-party. Remember that once you
          have opted in to allow us to send your information to the third-party,
          we cannot control what they do with your data; therefore, be sure to
          investigate their privacy policies before providing permission for us
          to share your information.
        </div>
        <div className="termsText">6 YOUR RIGHTS.</div>
        <div className="termsText">
          6.1 You have the right to ask us not to contact you for marketing
          purposes. You can exercise this right at any time by using any of the
          various "opt-out" options that we will always provide to you when we
          communicate with you. We won’t send you marketing messages if you tell
          us not to but we will still need to send you service-related messages.
        </div>
        <div className="termsText">
          6.2 Our website use cookies. If you wish to reject our cookies, you
          can configure your browser to do so.
        </div>
        <div className="termsText">
          6.3 We want to make sure that any data we hold about you is up to
          date. So, if you think your Personal Information is inaccurate, you
          can ask us to correct or remove it.
        </div>
        <div className="termsText">7 RETENTION OF DATA.</div>
        <div className="termsText">
          We will retain your Personal Information only for as long as is
          necessary for the purposes set out in this privacy policy or to comply
          with our legal obligations, resolve disputes, and enforce our legal
          agreements and policies.
        </div>
        <div className="termsText">8 OUR COMMITMENT TO SECURITY.</div>
        <div className="termsText">
          The security of your data is important to us. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security. However, we do employ a number
          of safeguards intended to mitigate the risk of unauthorised access or
          disclosure of your information. We will do our best to protect your
          Personal Information and we will use up to date technology that will
          help us to do this. We will at all times comply with our obligation
          under applicable law.
        </div>
        <div className="termsText">9 TRANSFER OF DATA.</div>
        <div className="termsText">
          9.1 We are based in and operate from South Africa. Your information,
          including Personal Information, may be transferred to and maintained
          on servers located outside of your country of residence, where the
          data privacy laws, regulations and standards, may not be equivalent to
          the laws in your country of residence.
        </div>
        <div className="termsText">
          9.2 We might transfer your Personal Information to places outside of
          South Africa and store it there, where our suppliers might Process it.
          If that happens, your Personal Information will only be transferred to
          and stored in country that has equivalent, or better, data protection
          legislation than South Africa or with a service provider which is
          subject to an agreement requiring it to comply with data protection
          requirements equivalent or better than those applicable in South
          Africa.
        </div>
        <div className="termsText">
          9.3 Your use of our Website, followed by your submission of
          information to us, represents your consent to such transfer.
        </div>
        <div className="termsText">
          9.4 We will take all steps reasonably necessary to ensure that your
          data is treated securely and in accordance with this Policy.
        </div>
        <div className="termsText">10 LINKS TO OTHER WEBSITES.</div>
        <div className="termsText">
          Our Website or Social Media platforms may contain links to and from
          websites, mobile applications or services of third parties,
          advertisers or affiliates. Please note that we are not responsible for
          the privacy practices of such other parties and advise you to read the
          privacy statements of each website you visit which collects Personal
          Information.
        </div>
        <div className="termsText">11 CHANGES TO THIS PRIVACY POLICY.</div>
        <div className="termsText">
          We may update this Privacy Policy from time to time. Any changes that
          we may make to our privacy policy will be posted on our website and
          will be effective from the date of posting.
        </div>
        <div className="termsText">
          12 ACCESS TO, CORRECTION AND DELETION OF YOUR PERSONAL INFORMATION.
        </div>
        <div className="termsText">
          12.1 You may request details of Personal Information which we hold
          about you under the Promotion of Access to Information Act, 2000
          (“PAIA”). Fees to obtain a copy or a description of Personal
          Information held about you are prescribed in terms of PAIA.
          Confirmation of whether or not we hold Personal Information about you
          may be requested free of charge.
        </div>
        <div className="termsText">
          12.2 You may request the correction of Personal Information CV Cloud
          holds about you. Please ensure that the information we hold about you
          is complete, accurate and up to date. If you fail to keep your
          information updated, or if your information is incorrect, CV Cloud may
          limit the products and services offered to you or elect not to open
          the account.
        </div>
        <div className="termsText">
          12.3 You have a right in certain circumstances to request the
          destruction or deletion of and, where applicable, to obtain
          restriction on the Processing of Personal Information held about you.
          If you wish to exercise this right, please contact us using the
          contact details set out below in clause 14..
        </div>
        <div className="termsText">
          12.4 You have a right to object on reasonable grounds to the
          Processing of your Personal Information where the Processing is
          carried out in order to protect our legitimate interests or your
          legitimate interests, unless the law provides for such Processing.
        </div>
        <div className="termsText">13 COMPLAINTS.</div>
        <div className="termsText">
          13.1 Should you believe that CV Cloud has utilised your Personal
          Information contrary to Applicable Laws, you undertake to first
          attempt to resolve any concerns with us.
        </div>
        <div className="termsText">
          13.2 If you are not satisfied with such process, you may have the
          right to lodge a complaint with the Information Regulator, using the
          contact details listed below:
        </div>
        <div className="termsText">
          E-mail address: complaints.IR@justice.gov.za
        </div>
        <div className="termsText">
          Physical address: JD House 27, Stiemens Street, Braamfontein,
          Johannesburg, 2001
        </div>
        <div className="termsText">14 IO CONTACT DETAILS.</div>
        <div className="termsText">
          If you have any comments or questions about this Statement or how we
          handle your Personal Information, please contact the Information
          Officer.
        </div>
        <div className="termsText">IO Name: Nico Rapelas</div>
        <div className="termsText">E-mail address: nicorapelas@gmail.com</div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
