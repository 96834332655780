import React from 'react'
import moment from 'moment'

import VideoPlayer from './VideoPlayer'
import './FirstImpressionView.css'

const FirstImpressionView = ({ videoPublicId, personalInfo }) => {
  console.log(videoPublicId)
  console.log(personalInfo)

  const videoOptions = {
    // cloudName: process.env.VIDEO_CLOUD_NAME,
    cloudName: 'cv-cloud',
    publicId: videoPublicId,
  }

  const renderContent = () => {
    const { fullName, dateOfBirth } = personalInfo[0]
    return (
      <>
        {!fullName || fullName.length < 1 ? null : (
          <div className="firstImpressionViewName">{fullName}</div>
        )}
        {!dateOfBirth || dateOfBirth.length < 1 ? null : (
          <div className="firstImpressionViewDateOfBirth">
            {moment(dateOfBirth).format('D MMMM YYYY')}
          </div>
        )}
        <div className="videoBed">
          <div className="videoContainer">
            <VideoPlayer options={videoOptions} />
          </div>
        </div>
      </>
    )
  }

  return renderContent()
}

export default FirstImpressionView
