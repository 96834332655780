import React from 'react'
import { BsSearch } from 'react-icons/bs'

import './pageNotFound.css'

const PageNotFound = () => {
  const renderContent = () => {
    return (
      <div className="pageNotFoundBed">
        <div className="pageNotFoundContainer">
          <div className="pageNotFoundTextIconBed">
            <div className="pageNotFoundText">
              <BsSearch className="pageNotFoundIcon" /> <br />
              page not found
            </div>
          </div>
        </div>
      </div>
    )
  }
  return renderContent()
}

export default PageNotFound
