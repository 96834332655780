import React from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

import ContactInfo from './ContactInfo'
import PersonalInfo from './PersonalInfo'
import Language from './Language'
import Attribute from './Attribute'
import Skill from './Skill'
import Interest from './Interest'
import PersonalSummary from './PersonalSummary'
import EmployHistory from './EmployHisory'
import Experience from './Experience'
import SecondEdu from './SecondEdu'
import TertEdu from './TertEdu'
import Photo from './Photo'
import Reference from './Reference'
import AttachedFirstImpressionButton from '../../modalButtons/AttachedFirstImpressionButton'
import AttachedCertificateButton from '../../modalButtons/AttachedCertificateButton'
import './cvCloud.css'

const CVCloud = ({
  photo,
  contactInfo,
  certificate,
  language,
  personalInfo,
  attribute,
  skill,
  interest,
  personalSummary,
  employHistory,
  experience,
  firstImpression,
  secondEdu,
  tertEdu,
  reference,
}) => {
  const printDownloadButton = () => {
    return (
      <div className="headerButtonsBed">
        <div className="printButtonBed">
          <button
            className="circular blue ui icon button"
            onClick={() => window.print()}
            title="print"
          >
            <div className="cv-print-btn-icon-container">
              <FontAwesomeIcon
                icon={faPrint}
                className="printDownloadButtonIncon"
              />
            </div>
          </button>
        </div>
        <div className="cvCloudAttachmentBed">
          <div className="cvCloudAttachmentContainer">
            <AttachedFirstImpressionButton firstImpression={firstImpression} />
            <AttachedCertificateButton certificate={certificate} />
          </div>
        </div>
      </div>
    )
  }

  const renderCV_Title = () => {
    if (!personalInfo) return null
    const { fullName, dateOfBirth } = personalInfo
    return (
      <div className="cvCloudCVTitleBed">
        {!fullName ? null : (
          <div className="cvCloudCVTitleNameText">{fullName}</div>
        )}
        {!dateOfBirth ? null : (
          <div className="cvCloudCVTitleDateText">
            {moment(dateOfBirth).format('D MMMM YYYY')}
          </div>
        )}
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        {printDownloadButton()}
        <div className="cvCloudBed">
          <div className="cvCloudContainer">
            <div className="cvCloudColumnLeft">
              <Photo photo={photo} />
              <ContactInfo contactInfo={contactInfo} />
              <PersonalInfo personalInfo={personalInfo} />
              <Language language={language} />
              <Attribute attribute={attribute} />
              <Skill skill={skill} />
              <Interest interest={interest} />
              <Reference reference={reference} />
            </div>
            <div className="cvCloudColumnRight">
              <div className="cvCloudCVTitleBed">{renderCV_Title()}</div>
              <PersonalSummary personalSummary={personalSummary} />
              <EmployHistory employHistory={employHistory} />
              <Experience experience={experience} />
              <SecondEdu secondEdu={secondEdu} />
              <TertEdu tertEdu={tertEdu} />
            </div>
          </div>
        </div>
      </>
    )
  }

  return renderContent()
}

export default CVCloud
