import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import './cvCloud.css'

const Interest = ({ interest }) => {
  const renderContent = () => {
    if (!interest || interest.length < 1) return null
    return (
      <>
        <div className="cvCloudLeftBitBed">
          <div className="cvCloudLeftBitContainer">
            <div className="cvCloudLeftHeading">
              {interest.length > 1 ? 'Interests' : 'Interest'}
            </div>
            {interest.map((int) => {
              return (
                <div className="cvCloudSkillBed" key={int._id}>
                  <div className="cvCloudLeftTextBed">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="cvCloudLeftBulletIcon"
                    />
                    <div className="cvCloudLeftBulletText">{int.interest}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <hr className="cvCloudRightHr" />
      </>
    )
  }

  return renderContent()
}

export default Interest
