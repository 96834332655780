import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { Provider as AuthProvider } from '../context/AuthContext'
import { Provider as UniversalProvider } from '../context/UniversalContext'
import { Provider as ModalProvider } from '../context/ModalContext'

import Test from './pages/Test'
import history from '../history'
import './app.css'

import Header from '../component/bits/Header'
import Landing from '../component/pages/landing/Landing'
import EmailVerification from './pages/auth/EmailVerification'
import ViewCV from './pages/viewCV/ViewCV'
import ManagementView from '../component/pages/management/ManagmentView'
import PageNotFound from './pages/pageNotFound/PageNotFound'
import TermsOfService from './pages/termsAndConditions/TermsOfService'
import PrivacyPolicy from './pages/termsAndConditions/PrivacyPolicy'
import ExitingInstructions from './pages/termsAndConditions/ExitingInstructions'
import Register from './pages/auth/register/Register'
import Login from './pages/auth/login/Login'
import ResetPassword from './pages/auth/resetPassword/ResetPassword'

const App = () => {
  return (
    <div className="appBed">
      <Router history={history}>
        <AuthProvider>
          <UniversalProvider>
            <ModalProvider>
              <Header />
              <Switch>
                <Route path="/" exact component={Landing} />
                <Route
                  path="/email-verified/:id"
                  exact
                  component={EmailVerification}
                />
                <Route path="/view-cv/:id" exact component={ViewCV} />
                <Route path="/td-view-cvs/" exact component={ManagementView} />
                <Route path="/test@123" exact component={Test} />
                <Route
                  path="/terms-of-service"
                  exact
                  component={TermsOfService}
                />
                <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                <Route
                  path="/exiting-instructions"
                  exact
                  component={ExitingInstructions}
                />
                <Route path="/register" exact component={Register} />
                <Route path="/login" exact component={Login} />
                <Route
                  path="/reset-password/:token"
                  exact
                  component={ResetPassword}
                />
                <Route path="*" component={PageNotFound} />
              </Switch>
            </ModalProvider>
          </UniversalProvider>
        </AuthProvider>
      </Router>
    </div>
  )
}

export default App
