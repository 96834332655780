import React, { useEffect } from 'react'
import { Cloudinary } from 'cloudinary-core'
import 'cloudinary-video-player/dist/cld-video-player.light.min'
import 'cloudinary-video-player/dist/cld-video-player.light.min.css'

const VideoPlayer = (props) => {
  useEffect(() => {
    return videoPlayerInit()
  }, [])

  const cloudinary = new Cloudinary({
    cloud_name: props.options.cloudName,
    secure: true,
  })

  const videoPlayerInit = () => {
    cloudinary.videoPlayer(document.querySelector('.fn-video'), {
      publicId: props.options.publicId,
      fluid: true,
      controls: true,
      preload: 'auto',
      mute: false,
      autoplay: false,
    })
  }


  return <video className="fn-video" />
}

export default VideoPlayer
