import React, { useState, useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope,
  faStar,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/fontawesome-free-regular'

import { Context as AuthContext } from '../../../../context/AuthContext'
import { Context as ModalContext } from '../../../../context/ModalContext'
import LoaderFullScreen from '../../../bits/LoaderFullScreen'
import NotificationModal from '../../../modals/notificationModal/NotificationModal'
import history from '../../../../history'
import registerLogo from '../../../../assets/images/logo-h65.png'
import './register.css'

const Register = () => {
  const [emailRegInputShow, setEmailRegInputShow] = useState(false)
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [introInputShow, setIntroInputShow] = useState(false)
  const [introAffiliateCode, setIntroAffiliateCode] = useState('')
  const [errorEmail, setErrorEmail] = useState(null)
  const [errorPassword, setErrorPassword] = useState(null)
  const [errorPassword2, setErrorPassword2] = useState(null)

  const {
    state: { loading, error, success, user },
    register,
    clearError,
    fetchUser,
  } = useContext(AuthContext)

  const { setNotificationModalVisible } = useContext(ModalContext)

  useEffect(() => {
    if (success) setNotificationModalVisible(true)
  }, [success])

  useEffect(() => {
    if (error) {
      if (error.email) setErrorEmail(error.email)
      if (error.password) setErrorPassword(error.password)
      if (error.password2) setErrorPassword2(error.password2)
    }
  }, [error])

  const clearErrors = () => {
    clearError()
    setErrorEmail(null)
    setErrorPassword(null)
    setErrorPassword2(null)
  }

  const introAffiliate = () => {
    if (!introInputShow) {
      return (
        <div className="introBed">
          <FontAwesomeIcon
            icon={faStar}
            className="introIcon"
            onClick={() => setIntroInputShow(true)}
          />
        </div>
      )
    }
    return (
      <div className="introBed">
        <div>
          <input
            className="introIconInput"
            placeholder="code"
            value={introAffiliateCode}
            onChange={(e) => setIntroAffiliateCode(e.target.value)}
          />
          <div className="attachCodeButtonBed">
            <div
              className="attachCodeButton"
              onClick={() => setIntroInputShow(false)}
            >
              Attach code
            </div>
          </div>
        </div>
      </div>
    )
  }

  const emailRegInput = () => {
    if (!emailRegInputShow) return null
    return (
      <div className="registerBed">
        <div className="registerBackArrowBed">
          <div className="registerBackArrowContainer">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="registerBackArow"
              onClick={() => setEmailRegInputShow(false)}
            />
          </div>
        </div>
        <div className="registerContainer">
          <div className="registerLogobed">
            <img
              className="registerLogo"
              src={registerLogo}
              alt="cvCloud-logo"
            />
          </div>
          <div className="registerHeading">Sign up with your email</div>
          <div className="registerEmailInputsBed">
            <div className="registerEmailInputsContainer">
              <input
                className="registerEmailInput"
                placeholder="Full Name"
                value={fullName}
                onFocus={() => clearErrors()}
                onChange={(e) => setFullName(e.target.value)}
              />
              <input
                className={
                  !errorEmail ? 'registerEmailInput' : 'registerEmailInputError'
                }
                type="email"
                placeholder="Email"
                value={email}
                onFocus={() => clearErrors()}
                onChange={(e) => setEmail(e.target.value)}
              />
              {!errorEmail ? null : (
                <div className="registerErrorText">{errorEmail}</div>
              )}
              <div className="passwordRulesBed">
                {password.length > 5 ? null : (
                  <div className="passwordRulesText">
                    Password must be 6 or more characters
                  </div>
                )}
                {password === password2 ? null : (
                  <div className="passwordRulesText">Passwords must match</div>
                )}
                {password.length < 6 || password !== password2 ? null : (
                  <div className="registerCheckIconBed">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="registerCheckIcon"
                    />
                  </div>
                )}
              </div>
              <input
                className={
                  !errorPassword
                    ? 'registerEmailInput'
                    : 'registerEmailInputError'
                }
                type="password"
                placeholder="Password"
                value={password}
                onFocus={() => clearErrors()}
                onChange={(e) => setPassword(e.target.value)}
              />
              {!errorPassword ? null : (
                <div className="registerErrorText">{errorPassword}</div>
              )}
              <input
                className={
                  !errorPassword2
                    ? 'registerEmailInput'
                    : 'registerEmailInputError'
                }
                type="password"
                placeholder="Confirm password"
                value={password2}
                onFocus={() => clearErrors()}
                onChange={(e) => setPassword2(e.target.value)}
              />
              {!errorPassword2 ? null : (
                <div className="registerErrorText">{errorPassword2}</div>
              )}
            </div>
          </div>
          <div
            className="registerButtonBed"
            onClick={() =>
              register({
                fullName,
                email,
                password,
                password2,
                introAffiliateCode,
              })
            }
          >
            <div className="registerButton">Sign up</div>
          </div>
          <div
            className="registerLinkToLogin"
            onClick={() => history.push('/login')}
          >
            Already have a account? Login here
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (loading) return <LoaderFullScreen />
    if (emailRegInputShow) return emailRegInput()
    return (
      <div className="registerBed">
        <div className="registerContainer">
          <div className="registerLogobed">
            <img
              className="registerLogo"
              src={registerLogo}
              alt="cvCloud-logo"
            />
          </div>
          <div className="registerHeading">Sign up with us</div>
          <div className="registerButtonBed">
            <div
              className="registerGoogleButton"
              onClick={() => console.log('Google')}
            >
              <FontAwesomeIcon icon={faGoogle} className="registerButtonIcon" />
              <div className="registerButtonText">Google</div>
            </div>
          </div>
          <div className="registerButtonBed">
            <div
              className="registerFacebookButton"
              onClick={() => console.log('Facebook')}
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                className="registerButtonIcon"
              />
              <div className="registerButtonText">Facebook</div>
            </div>
          </div>
          <div className="registerButtonBed">
            <div
              className="registerEmailButton"
              onClick={() => setEmailRegInputShow(true)}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="registerButtonIcon"
              />
              <div className="registerButtonText">Email</div>
            </div>
          </div>
          <div
            className="registerLinkToLogin"
            onClick={() => history.push('/login')}
          >
            Already have a account? Login here
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <NotificationModal bit="register" dismissRoute="/login" />
      {introAffiliate()}
      {renderContent()}
      <button onClick={() => fetchUser()}>check user</button>
    </>
  )
}

export default Register
