import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import './cvCloud.css'

const Attribute = ({ attribute }) => {
  const renderContent = () => {
    if (!attribute || attribute.length < 1) return null
    return (
      <>
        <div className="cvCloudLeftBitBed">
          <div className="cvCloudLeftBitContainer">
            <div className="cvCloudLeftHeading">
              {attribute.length > 1 ? 'Attributes' : 'Attribute'}
            </div>
            {attribute.map((att) => {
              return (
                <div className="cvCloudSkillBed" key={att._id}>
                  <div className="cvCloudLeftTextBed">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="cvCloudLeftBulletIcon"
                    />
                    <div className="cvCloudLeftBulletText">{att.attribute}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <hr className="cvCloudLeftHr" />
      </>
    )
  }

  return renderContent()
}

export default Attribute
