import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGraduationCap,
  faComment,
  faCalendarAlt,
  faCertificate,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'

import './cvCloud.css'

const TertEdu = ({ tertEdu }) => {
  const renderContent = () => {
    if (!tertEdu || tertEdu.length < 1) return null
    return (
      <>
        <div className="cvCloudRightBitBed">
          <div className="cvCloudRightHeading">Tertiary education</div>
          {tertEdu.map((ter) => {
            return (
              <div
                className={
                  tertEdu.length > 1
                    ? 'cvCloudRightFieldsTwoBed'
                    : 'cvCloudRightFieldsBed'
                }
                key={ter._id}
              >
                <div className="cvCloudRightFieldBed">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="cvCloudRightFieldIcon"
                  />
                  <div className="cvCloudRightFieldText">
                    {ter.instituteName}
                  </div>
                </div>
                {!ter.startDate || ter.startDate.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="cvCloudRightFieldText">
                      {ter.startDate}
                      {!ter.endDate ? null : ` - ${ter.endDate}`}
                    </div>
                  </div>
                )}
                {!ter.certificationType ||
                ter.certificationType.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faCertificate}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="cvCloudRightFieldText">
                      {ter.certificationType}
                    </div>
                  </div>
                )}
                {!ter.description || ter.description.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faComment}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="cvCloudRightFieldText">
                      {ter.description}
                    </div>
                  </div>
                )}
                {!ter.additionalInfo || ter.additionalInfo.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="cvCloudRightFieldText">
                      {ter.additionalInfo}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <hr className="cvCloudRightHr" />
      </>
    )
  }

  return renderContent()
}

export default TertEdu
