import ngrokApi from '../api/ngrok'
import createDataContext from './createDataContext'

// Reducer
const UniversalContext = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'ADD_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'CLEAR_ERRORS':
      return { ...state, error: action.payload }
    case 'SET_ON_VIEW_CV_PAGE':
      return { ...state, onViewCVPage: action.payload }
    case 'SET_ON_LANDING_PAGE':
      return { ...state, onLandingPage: action.payload }
    case 'SET_SHOW_MENU_MODAL':
      return { ...state, showMenuModal: action.payload }
    case 'SET_MENU_MODAL_BIT':
      return { ...state, menuModalBit: action.payload }
    case 'SET_SHOW_MEDIA_MODAL':
      return { ...state, showMediaModal: action.payload }
    case 'SET_MEDIA_MODAL_BIT':
      return { ...state, mediaModalBit: action.payload }
    case 'SET_SELECTED_CERTIFICATE':
      return { ...state, selectedCertificate: action.payload }
    case 'SET_VIDEO_PUBLIC_ID':
      return { ...state, videoPublicId: action.payload }
    case 'SET_HIDE_HEADER&FOORTER':
      return { ...state, hideHeaderAndFooter: action.payload }
    case 'FETCH_CV_TO_VIEW':
      return { ...state, curriculumVitae: action.payload, loading: false }
    case 'SET_API_MESSAGE':
      return { ...state, apiMessage: action.payload, loading: false }
    case 'FETCH_CV_TO_VIEW_ALL':
      return { ...state, curriculumVitaeAll: action.payload, loading: false }
    case 'CLEAR_API_MESSAGES':
      return { ...state, apiMessage: action.payload }
    default:
      return state
  }
}

// Actions
const fetchCV_ToView = (dispatch) => async (id) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/api/curriculum-vitae/view', id)
    console.log(response.data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    dispatch({
      type: 'FETCH_CV_TO_VIEW',
      payload: response.data.curriculumVitae,
    })
    return
  } catch (error) {
    console.log(error)
    return
  }
}

const clearErrors = (dispatch) => () => {
  dispatch({ type: 'CLEAR_ERRORS', payload: null })
}

const setOnViewCVPage = (dispatch) => (value) => {
  dispatch({ type: 'SET_ON_VIEW_CV_PAGE', payload: value })
}

const setOnLandingPage = (dispatch) => (value) => {
  dispatch({ type: 'SET_ON_LANDING_PAGE', payload: value })
}

const setShowMenuModal = (dispatch) => (value) => {
  dispatch({ type: 'SET_SHOW_MENU_MODAL', payload: value })
}

const setMenuModalBit = (dispatch) => (bit) => {
  dispatch({ type: 'SET_MENU_MODAL_BIT', payload: bit })
}

const setShowMediaModal = (dispatch) => (value) => {
  dispatch({ type: 'SET_SHOW_MEDIA_MODAL', payload: value })
}

const setMediaModalBit = (dispatch) => (bit) => {
  dispatch({ type: 'SET_MEDIA_MODAL_BIT', payload: bit })
}

const setSelectedCertificate = (dispatch) => (item) => {
  dispatch({ type: 'SET_SELECTED_CERTIFICATE', payload: item })
}

const setVideoPublicId = (dispatch) => (id) => {
  dispatch({ type: 'SET_VIDEO_PUBLIC_ID', payload: id })
}

const setHideHeaderAndFooter = (dispatch) => (value) => {
  dispatch({ type: 'SET_HIDE_HEADER&FOORTER', payload: value })
}

const fetchCV_ToViewAll = (dispatch) => async (pin) => {
  dispatch({ type: 'LOADING' })
  const response = await ngrokApi.post('/api/curriculum-vitae/all', pin)
  if (response.data.message) {
    dispatch({ type: 'SET_API_MESSAGE', payload: response.data.message })
    return
  }
  dispatch({ type: 'FETCH_CV_TO_VIEW_ALL', payload: response.data })
  return
}

const clearApiMessage = (dispatch) => () => {
  dispatch({ type: 'CLEAR_API_MESSAGES', payload: null })
}

const testServer = () => async () => {
  console.log('test server action')
  const response = await ngrokApi.post('/auth/user/verify-email', {
    msg: 'hey...!',
  })
  console.log(response.data)
}

export const { Context, Provider } = createDataContext(
  UniversalContext,
  {
    setOnViewCVPage,
    setOnLandingPage,
    setShowMenuModal,
    setMenuModalBit,
    setShowMediaModal,
    setMediaModalBit,
    setSelectedCertificate,
    setVideoPublicId,
    setHideHeaderAndFooter,
    fetchCV_ToView,
    testServer,
    clearErrors,
    fetchCV_ToViewAll,
    clearApiMessage,
  },
  // Initial state
  {
    loading: null,
    error: null,
    onViewCVPage: false,
    onLandingPage: false,
    showMenuModal: false,
    menuModalBit: null,
    showMediaModal: null,
    mediaModalBit: null,
    selectedCertificate: null,
    videoPublicId: null,
    hideHeaderAndFooter: false,
    curriculumVitae: null,
    curriculumVitaeAll: null,
    apiMessage: null,
  }
)
