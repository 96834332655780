import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import CertificateMenu from '../../cvTemplates/CertificateMenu'
import { Context as UniversalContext } from '../../../context/UniversalContext'
import './menuModal.css'

const MenuModal = ({ certificate }) => {
  const {
    state: { menuModalBit },
    setShowMenuModal,
    setMenuModalBit,
    setMediaModalBit,
    setHideHeaderAndFooter,
  } = useContext(UniversalContext)

  const renderMenuModalBit = () => {
    switch (menuModalBit) {
      case 'certificate':
        return <CertificateMenu certificate={certificate} />
      default:
        break
    }
  }

  const renderContent = () => {
    return (
      <div className="menuModalBed">
        <div className="menuModalContainer">
          <div className="menuModalCloseIconBed">
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => {
                setMenuModalBit(null)
                setMediaModalBit(null)
                setHideHeaderAndFooter(false)
                setShowMenuModal(false)
              }}
              className="menuModalCloseIcon"
            />
          </div>
          <div className="menuModalHeading">{menuModalBit}s</div>
          {renderMenuModalBit()}
        </div>
      </div>
    )
  }

  return renderContent()
}

export default MenuModal
