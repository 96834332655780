import ngrokApi from '../api/ngrok'
import createDataContext from './createDataContext'

// Reducer
const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'STOP_LOADING':
      return { ...state, loading: false }
    case 'FETCH_USER':
      return { ...state, user: action.payload, loading: false }
    case 'ADD_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'CLEAR_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'ADD_SUCCESS':
      return { ...state, success: action.payload, loading: false }
    case 'CLEAR_SUCCESS':
      return { ...state, success: action.payload, loading: false }
    default:
      return state
  }
}

// Actions
const fetchUser = (dispatch) => async () => {
  const response = await ngrokApi.get('/auth/user/fetch-user')
  dispatch({ type: 'FETCH_USER', payload: response.data })
}

const register =
  (dispatch) =>
  async ({ fullName, email, password, password2, introAffiliateCode }) => {
    dispatch({ type: 'LOADING' })
    try {
      const response = await ngrokApi.post('/auth/user/register', {
        fullName,
        email,
        password,
        password2,
        affiliatceIntroCode: introAffiliateCode,
      })
      if (response.data.error)
        dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      if (response.data.success)
        dispatch({ type: 'ADD_SUCCESS', payload: response.data })
    } catch (err) {
      dispatch({ type: 'STOP_LOADING' })
      dispatch({
        type: 'ADD_ERROR',
        payload: err.response.data,
      })
    }
  }

const login =
  (dispatch) =>
  async ({ email, password, platform }) => {
    dispatch({ type: 'LOADING' })
    try {
      const response = await ngrokApi.post('/auth/user/login', {
        email,
        password,
        platform,
      })
      if (response.data.error) {
        dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      }
    } catch (err) {
      dispatch({
        type: 'ADD_ERROR',
        payload: err.response.data,
      })
    }
  }

const setEmailVerified = (dispatch) => async (id) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/auth/user/verify-email', id)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
    } else {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
    }
  } catch (error) {
    console.log(error)
    return
  }
}

const passwordReset = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/auth/user/reset', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
      return
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
      return
    }
  } catch (error) {
    console.log(error)
    return
  }
}

const clearError = (dispatch) => async () => {
  dispatch({ type: 'CLEAR_ERROR', payload: null })
}

const clearSuccess = (dispatch) => async () => {
  dispatch({ type: 'CLEAR_SUCCESS', payload: null })
}

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    fetchUser,
    setEmailVerified,
    passwordReset,
    clearError,
    clearSuccess,
    register,
    login,
  },
  { user: null, loading: null, error: null, success: null }
)
