import React from 'react'

import ProficiencyOne from '../../proficiencyDots/ProficiencyOne'
import ProficiencyTwo from '../../proficiencyDots/ProficiencyTwo'
import ProficiencyThree from '../../proficiencyDots/ProficiencyThree'
import ProficiencyFour from '../../proficiencyDots/ProficiencyFour'
import ProficiencyFive from '../../proficiencyDots/ProficiencyFive'
import './cvCloud.css'

const Skill = ({ skill }) => {
  const renderContent = () => {
    if (!skill || skill.length < 1) return null
    return (
      <>
        <div className="cvCloudLeftBitBed">
          <div className="cvCloudLeftBitContainer">
            <div className="cvCloudLeftHeading">
              {skill.length > 1 ? 'Skills' : 'Skill'}
            </div>
            {skill.map((ski) => {
              return (
                <div className="cvCloudSkillBed" key={ski._id}>
                  <div className="cvCloudLeftSkillTextBed">
                    <div className="cvCloudLeftBulletSkillText">
                      {ski.skill}
                    </div>
                  </div>
                  <div className="cvCloudProficiencyBed">
                    {ski.proficiency !== 1 ? null : (
                      <ProficiencyOne proficiencyColor="proficiencyWhite" />
                    )}
                    {ski.proficiency !== 2 ? null : (
                      <ProficiencyTwo proficiencyColor="proficiencyWhite" />
                    )}
                    {ski.proficiency !== 3 ? null : (
                      <ProficiencyThree proficiencyColor="proficiencyWhite" />
                    )}
                    {ski.proficiency !== 4 ? null : (
                      <ProficiencyFour proficiencyColor="proficiencyWhite" />
                    )}
                    {ski.proficiency !== 5 ? null : (
                      <ProficiencyFive proficiencyColor="proficiencyWhite" />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <hr className="cvCloudLeftHr" />
      </>
    )
  }

  return renderContent()
}

export default Skill
