import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as circleFill } from '@fortawesome/free-solid-svg-icons'

import './proficiency.css'

const ProficiencyFive = ({ proficiencyColor }) => {
  return (
    <div>
      <FontAwesomeIcon icon={circleFill} className={proficiencyColor} />
      <FontAwesomeIcon icon={circleFill} className={proficiencyColor} />
      <FontAwesomeIcon icon={circleFill} className={proficiencyColor} />
      <FontAwesomeIcon icon={circleFill} className={proficiencyColor} />
      <FontAwesomeIcon icon={circleFill} className={proficiencyColor} />
    </div>
  )
}

export default ProficiencyFive
