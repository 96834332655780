import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GiFemale, GiMale } from 'react-icons/gi'
import {
  faGlobeAfrica,
  faGlobeEurope,
  faAddressCard,
  faIdCard,
  faIdCardAlt,
} from '@fortawesome/free-solid-svg-icons'

import './cvCloud.css'

const PersonalInfo = ({ personalInfo }) => {
  const renderContent = () => {
    if (!personalInfo || personalInfo.length < 1) return null
    return (
      <>
        <div className="cvCloudLeftBitBed">
          <div className="cvCloudLeftBitContainer">
            <div className="cvCloudLeftHeading">Personal information</div>
            {!personalInfo.nationality ||
            personalInfo.nationality.length < 1 ? (
              <div className="cvCloudLeftFieldBed">
                <FontAwesomeIcon
                  icon={faGlobeAfrica}
                  className="cvCloudLeftFieldIcon"
                />
                <div className="cvCloudLeftFieldText">South African</div>
              </div>
            ) : (
              <div className="cvCloudLeftFieldBed">
                <FontAwesomeIcon
                  icon={faGlobeEurope}
                  className="cvCloudLeftFieldIcon"
                />
                <div className="cvCloudLeftFieldText">
                  {personalInfo.nationality}
                </div>
              </div>
            )}
            {!personalInfo.idNumber ||
            personalInfo.idNumber.length < 1 ? null : (
              <div className="cvCloudLeftFieldBed">
                <FontAwesomeIcon
                  icon={faIdCard}
                  className="cvCloudLeftFieldIcon"
                />
                <div className="cvCloudLeftFieldText">
                  {personalInfo.idNumber}
                </div>
              </div>
            )}
            {!personalInfo.ppNumber ||
            personalInfo.ppNumber.length < 1 ? null : (
              <div className="cvCloudLeftFieldBed">
                <FontAwesomeIcon
                  icon={faAddressCard}
                  className="cvCloudLeftFieldIcon"
                />
                <div className="cvCloudLeftFieldText">
                  {personalInfo.ppNumber}
                </div>
              </div>
            )}
            {!personalInfo.gender || personalInfo.gender.length < 1 ? null : (
              <div className="cvCloudLeftFieldBed">
                {personalInfo.gender === 'male' ? (
                  <GiMale className="cvCloudLeftFieldIcon" />
                ) : (
                  <GiFemale className="cvCloudLeftFieldIcon" />
                )}
                <div className="cvCloudLeftFieldText">
                  {personalInfo.gender}
                </div>
              </div>
            )}
            {!personalInfo.licenseCode ||
            personalInfo.licenseCode.length < 1 ? null : (
              <div className="cvCloudLeftFieldBed">
                <FontAwesomeIcon
                  icon={faIdCardAlt}
                  className="cvCloudLeftFieldIcon"
                />
                <div className="cvCloudLeftFieldText">
                  Drivers license code: {personalInfo.licenseCode}
                </div>
              </div>
            )}
          </div>
        </div>
        <hr className="cvCloudLeftHr" />
      </>
    )
  }

  return renderContent()
}

export default PersonalInfo
