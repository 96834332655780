import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserAlt,
  faBuilding,
  faPhone,
  faAt,
} from '@fortawesome/free-solid-svg-icons'

import './cvCloud.css'

const Reference = ({ reference }) => {
  const renderContent = () => {
    if (!reference || reference.length < 1) return null
    return (
      <div className="cvCloudLeftBitBed">
        <div className="cvCloudLeftBitContainer">
          <div className="cvCloudLeftHeading">
            {reference.length > 1 ? 'References' : 'Reference'}
          </div>
          {reference.map((ref) => {
            return (
              <div className="cvCloudLeftFieldsBed" key={ref._id}>
                {!ref.name || ref.name.length < 1 ? null : (
                  <div className="cvCloudLeftReferenceTextBed">
                    <FontAwesomeIcon
                      icon={faUserAlt}
                      className="cvCloudLeftReferenceIcon"
                    />
                    <div className="cvCloudLeftBulletText">{ref.name}</div>
                  </div>
                )}
                {!ref.company || ref.company.length < 1 ? null : (
                  <div className="cvCloudLeftReferenceTextBed">
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="cvCloudLeftReferenceIcon"
                    />
                    <div className="cvCloudLeftBulletText">{ref.company}</div>
                  </div>
                )}
                {!ref.phone || ref.phone.length < 1 ? null : (
                  <div className="cvCloudLeftReferenceTextBed">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="cvCloudLeftReferenceIcon"
                    />
                    <div className="cvCloudLeftBulletText">{ref.phone}</div>
                  </div>
                )}
                {!ref.email || ref.email.length < 1 ? null : (
                  <div className="cvCloudLeftReferenceTextBed">
                    <FontAwesomeIcon
                      icon={faAt}
                      className="cvCloudLeftReferenceIcon"
                    />
                    <div className="cvCloudLeftBulletText">{ref.email}</div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return renderContent()
}

export default Reference
