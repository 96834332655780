import React, { useContext } from 'react'

import { Context as UniversalContext } from '../../context/UniversalContext'
import { Context as ModalContext } from '../../context/ModalContext'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BurgerMenuModal from '../modals/burgerMenuModal/BurgerMenuModal'
import headerLogo from '../../assets/images/logo-w160.png'
import history from '../../history'
import './header.css'

const Header = () => {
  const {
    state: { hideHeaderAndFooter },
  } = useContext(UniversalContext)

  const { setBurgerMenuModalVisible } = useContext(ModalContext)

  const renderContent = () => {
    if (hideHeaderAndFooter) return null
    return (
      <div className="headerBed">
        <div className="headerLogoBed" onClick={() => history.push('/')}>
          <img className="headerLogo" src={headerLogo} alt="header logo" />
        </div>
        <FontAwesomeIcon
          icon={faBars}
          className="headerMenuButton"
          onClick={() => setBurgerMenuModalVisible(true)}
        />
      </div>
    )
  }

  return (
    <>
      <BurgerMenuModal />
      {renderContent()}
    </>
  )
}

export default Header
