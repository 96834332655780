import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

import { Context as UniversalContext } from '../../context/UniversalContext'
import './attachedFirstImpressionButton.css'

const AttachedFirstImpressionButton = ({ firstImpression }) => {
  const {
    setShowMediaModal,
    setMediaModalBit,
    setVideoPublicId,
    setHideHeaderAndFooter,
  } = useContext(UniversalContext)

  const renderContent = () => {
    if (!firstImpression || firstImpression.length === 0) return null
    const publicId = firstImpression[0].publicId
    return (
      <div className="firstImpressionBed">
        <div
          className="firstImpressionContainer"
          onClick={() => {
            setMediaModalBit('firstImpression')
            setVideoPublicId(publicId)
            setHideHeaderAndFooter(true)
            setShowMediaModal(true)
          }}
          title="play video"
        >
          <FontAwesomeIcon
            icon={faPaperclip}
            className="fisrtImpressionClipIcon"
          />
          <div className="firstUImpressionText">first impression video</div>
          <FontAwesomeIcon
            icon={faPlayCircle}
            className="firstImpressionPlayIcon"
          />
        </div>
      </div>
    )
  }

  return renderContent()
}

export default AttachedFirstImpressionButton
