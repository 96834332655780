import React, { useContext, useEffect, useState } from 'react'

import LoaderFullScreen from '../../bits/LoaderFullScreen'
import MenuModal from '../../modals/menuModal/MenuModal'
import MediaModal from '../../modals/mediaModal/MediaModal'
import NotificationModal from '../../modals/notificationModal/NotificationModal'
import CVCloud from '../../cvTemplates/cvCloud/CVCloud'
import { Context as UniversalContext } from '../../../context/UniversalContext'
import { Context as ModalContext } from '../../../context/ModalContext'
import './viewCV.css'

const ViewCV = (props) => {
  const { id } = props.match.params
  const [checkParams, setCheckParams] = useState(true)

  const {
    state: { loading, error, curriculumVitae, showMenuModal, showMediaModal },
    setOnViewCVPage,
    fetchCV_ToView,
  } = useContext(UniversalContext)

  const { setNotificationModalVisible } = useContext(ModalContext)

  useEffect(() => {
    setOnViewCVPage(true)
    fetchCV()
    return () => {
      setOnViewCVPage(false)
    }
  }, [checkParams])

  useEffect(() => {
    if (error) setNotificationModalVisible(true)
  }, [error])

  const fetchCV = () => {
    if (!checkParams) return null
    if (id) {
      fetchCV_ToView({ id })
      setCheckParams(false)
    }
  }

  const renderContent = () => {
    if (loading) return <LoaderFullScreen />
    if (!curriculumVitae || curriculumVitae.length < 1) return null
    const {
      _photo,
      _attribute,
      _contactInfo,
      _certificate,
      _employHistory,
      _experience,
      _firstImpression,
      _interest,
      _language,
      _personalInfo,
      _personalSummary,
      _reference,
      _secondEdu,
      _skill,
      _tertEdu,
    } = curriculumVitae[0]
    if (showMediaModal) return <MediaModal personalInfo={_personalInfo} />
    if (showMenuModal) return <MenuModal certificate={_certificate} />
    return (
      <CVCloud
        photo={_photo}
        attribute={_attribute}
        contactInfo={_contactInfo[0]}
        certificate={_certificate}
        employHistory={_employHistory}
        experience={_experience}
        firstImpression={_firstImpression}
        interest={_interest}
        language={_language}
        personalInfo={_personalInfo[0]}
        personalSummary={_personalSummary[0]}
        reference={_reference}
        secondEdu={_secondEdu}
        skill={_skill}
        tertEdu={_tertEdu}
      />
    )
  }

  return (
    <div className="viewCvBed">
      <NotificationModal bit="viewCv" dismissRoute="/" />
      {renderContent()}
    </div>
  )
}

export default ViewCV
