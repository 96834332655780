import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faBell } from '@fortawesome/free-solid-svg-icons'

import { Context as ModalContext } from '../../../context/ModalContext'
import { Context as UniversalContext } from '../../../context/UniversalContext'
import { Context as AuthContext } from '../../../context/AuthContext'
import history from '../../../history'
import './notificationModal.css'

const NotificationModal = ({ bit, dismissRoute }) => {
  const {
    state: { notificationModalVisible },
    setNotificationModalVisible,
  } = useContext(ModalContext)

  const {
    state: { error },
    clearErrors: clearCvViewErrors,
  } = useContext(UniversalContext)

  const { clearSuccess } = useContext(AuthContext)

  if (!notificationModalVisible) return null

  const viewCvContent = () => {
    if (!error) return null
    if (error === `"CurriculumVitae" requested not found`) {
      return (
        <div className="notificationModalText">
          The Curriculum Vitae that was shared with you is no longer available.
          Contact the sender and ask them to share a newly generated CV.
        </div>
      )
    }
  }

  const registerSuccess = () => {
    return (
      <div className="notificationModalText">
        An 'email verification' email has been sent to you. Please open the
        email and follow the provided instructions.
      </div>
    )
  }

  const notVerified = () => {
    return (
      <div className="notificationModalText">
        Email address not yet verified
      </div>
    )
  }

  const contentSelecter = () => {
    if (bit === 'viewCv') return viewCvContent()
    if (bit === 'register') return registerSuccess()
    if (bit === 'notVerified') return notVerified()
  }

  return ReactDOM.createPortal(
    <div className="ui dimmer active">
      <div className="notificationModalViewCvBed">
        <FontAwesomeIcon icon={faBell} className="notificationModalIcon" />
        {contentSelecter()}
        <div className="notificationModalCloseBtnBed">
          <div className="notificationModalCloseBtnText">close</div>
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={() => {
              clearCvViewErrors()
              setNotificationModalVisible(false)
              clearSuccess()
              history.push(`${dismissRoute}`)
            }}
            className="notificationModalCloseBtnIcon"
          />
        </div>
      </div>
    </div>,
    document.querySelector('#modal')
  )
}

export default NotificationModal
