import React from 'react'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './certificateMenu.css'

const CertificateMenu = ({ certificate }) => {
  const renderContent = () => {
    if (!certificate || certificate.length < 1) return null
    return (
      <>
        {certificate.map((cer) => {
          console.log(cer)
          let url = cer.pdfUrl ? cer.pdfUrl : cer.photoUrl
          return (
            <div className="certificateMenuBed" key={cer._id}>
              <div className="certificateMenuContainer">
                <div className="certificateMenuIconTextBed">
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    className="certificateMenuClipIcon"
                  />
                  <div className="certificateMenuText">{cer.title}</div>
                </div>
                <div className="certificateMenuButtonBed">
                  <button
                    onClick={() => window.open(url)}
                    className="certificateMenuActionButton"
                  >
                    <div className="certificateMenuActionButtonText">open</div>
                  </button>
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  return renderContent()
}

export default CertificateMenu
