import React, { useEffect, useContext, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

import { Context as AuthContext } from '../../../context/AuthContext'
import { Context as UniversalContext } from '../../../context/UniversalContext'
import emailVerifyLogo from '../../../assets/images/logo-w250.png'
import LoaderFullScreen from '../../bits/LoaderFullScreen'
import './emailVerified.css'

const EmailVerification = (props) => {
  const { id } = props.match.params
  const [checkParams, setCheckParams] = useState(true)
  const [navToAppStore, setNavToAppStore] = useState(false)

  const {
    state: { loading, error, success },
    setEmailVerified,
  } = useContext(AuthContext)

  const { setHideHeaderAndFooter } = useContext(UniversalContext)

  useEffect(() => {
    verifyUserEmail()
  }, [checkParams])

  useEffect(() => {
    setHideHeaderAndFooter(true)
  }, [])

  const verifyUserEmail = () => {
    if (!checkParams) return null
    if (id) {
      setEmailVerified({ id })
      setCheckParams(false)
    }
  }

  useEffect(() => {
    if (navToAppStore) {
      if (isAndroid) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=app.cvcloud.www'
      } else if (isIOS) {
        window.location.href =
          'https://apps.apple.com/za/app/cv-cloud/id1600222322'
      } else {
        window.location.href = 'https://www.cvcloud.app/'
      }
    }
  }, [navToAppStore])

  const renderContent = () => {
    if (loading) return <LoaderFullScreen />
    return (
      <div className="emailVerifiedBed">
        <div className="emailVerifiedContainer">
          <div className="emailVerifiedContentBed">
            {!error ? null : (
              <div className="emailVerifiedText">An Error Has Occurred</div>
            )}
            {!success ? null : (
              <>
                <img
                  className="emailVerifyLogo"
                  src={emailVerifyLogo}
                  alt="cvCloud-logo"
                />
                <div className="emailVerifiedText">
                  Your account has been verified successfully, you can now open
                  the CV Cloud app and login using your credentials
                </div>
                <button
                  className="emailVerifiedButton"
                  onClick={() => setNavToAppStore(true)}
                >
                  <div className="emailVerifiedButtonText">Ok</div>
                </button>
                {/* Add button to open mobile or web app */}
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  return renderContent()
}

export default EmailVerification
