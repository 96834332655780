import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import FirstImpressionView from '../../cvTemplates/firstImpression/FirstImpressionView'
import { Context as UniversalContext } from '../../../context/UniversalContext'
import './mediaModal.css'

const MediaModal = ({ personalInfo }) => {
  const {
    state: { mediaModalBit, selectedCertificate, videoPublicId },
    setShowMediaModal,
    setHideHeaderAndFooter,
    setVideoPublicId,
    setSelectedCertificate,
    setShowMenuModal,
  } = useContext(UniversalContext)

  const renderMediaModalBit = () => {
    switch (mediaModalBit) {
      case 'certificate':
        return null
      case 'firstImpression':
        return (
          <FirstImpressionView
            videoPublicId={videoPublicId}
            personalInfo={personalInfo}
          />
        )
      default:
        break
    }
  }

  const closeButtonFunction = () => {
    if (mediaModalBit === 'firstImpression') {
      setShowMediaModal(false)
      setHideHeaderAndFooter(false)
      setVideoPublicId(null)
      setShowMenuModal(false)
    }
    if (mediaModalBit === 'certificate') {
      setSelectedCertificate(null)
      setShowMediaModal(false)
      setHideHeaderAndFooter(false)
      setShowMenuModal(true)
    }
  }

  const renderContent = () => {
    return (
      <div className="mediaModalBed">
        <div className="mediaModalContainer">
          <div className="mediaModalCloseIconBed">
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => closeButtonFunction()}
              className="mediaModalCloseIcon"
            />
          </div>
          <div className="mediaModalHeading">
            {mediaModalBit === 'certificate' ? selectedCertificate.title : null}
          </div>
          {renderMediaModalBit()}
        </div>
      </div>
    )
  }

  return renderContent()
}

export default MediaModal
