import React from 'react'

import './cvCloud.css'

const PersonalSummary = ({ personalSummary }) => {
  const renderContent = () => {
    if (!personalSummary || personalSummary.length < 1) return null
    return (
      <>
        <div className="cvCloudRightBitBed">
          <div className="cvCloudRightHeading">Personal summary</div>
          <div className="cvCloudRightFieldText">{personalSummary.content}</div>
        </div>
        <hr className="cvCloudRightHr" />
      </>
    )
  }

  return renderContent()
}

export default PersonalSummary
