import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import ProficiencyOne from '../../proficiencyDots/ProficiencyOne'
import ProficiencyTwo from '../../proficiencyDots/ProficiencyTwo'
import ProficiencyThree from '../../proficiencyDots/ProficiencyThree'
import ProficiencyFour from '../../proficiencyDots/ProficiencyFour'
import ProficiencyFive from '../../proficiencyDots/ProficiencyFive'
import './cvCloud.css'

const Language = ({ language }) => {
  const renderProfiencyDots = (val) => {
    if (val === 1) return <ProficiencyOne proficiencyColor="proficiencyWhite" />
    if (val === 2) return <ProficiencyTwo proficiencyColor="proficiencyWhite" />
    if (val === 3)
      return <ProficiencyThree proficiencyColor="proficiencyWhite" />
    if (val === 4)
      return <ProficiencyFour proficiencyColor="proficiencyWhite" />
    if (val === 5)
      return <ProficiencyFive proficiencyColor="proficiencyWhite" />
  }

  const renderContent = () => {
    if (!language || language.length < 1) return null
    return (
      <>
        <div className="cvCloudLeftBitBed">
          <div className="cvCloudLeftBitContainer">
            <div className="cvCloudLeftHeading">Languages</div>
            <div className="cvCloudLanguageMain">
              {language.map((lang) => {
                return (
                  <div className="cvCloudLanguageBed" key={lang._id}>
                    <div className="cvCloudLanguageHeaderBed">
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="cvCloudLeftBulletIcon"
                      />
                      <div className="cvCloudLanguageLabel">
                        {lang.language}
                      </div>
                    </div>
                    <div className="cvCloudLanguageProficiencyBed">
                      <div className="cvCloudLanguageProficiency">
                        <div className="cvCloudLanguageProficiencyText">
                          write
                        </div>
                        {renderProfiencyDots(lang.write)}
                      </div>
                      <div className="cvCloudLanguageProficiency">
                        <div className="cvCloudLanguageProficiencyText">
                          read
                        </div>
                        {renderProfiencyDots(lang.read)}
                      </div>
                      <div className="cvCloudLanguageProficiency">
                        <div className="cvCloudLanguageProficiencyText">
                          speak
                        </div>
                        {renderProfiencyDots(lang.speak)}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <hr className="cvCloudLeftHr" />
      </>
    )
  }

  return renderContent()
}

export default Language
