import React, { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

const Test = () => {
  const [navToAppStore, setNavToAppStore] = useState(false)

  useEffect(() => {
    if (navToAppStore) {
      if (isAndroid) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=app.cvcloud.www'
      } else if (isIOS) {
        window.location.href =
          'https://apps.apple.com/za/app/cv-cloud/id1600222322'
      } else {
        window.location.href = 'https://www.cvcloud.app/'
      }
    }
  }, [navToAppStore])

  const renderContent = () => {
    return (
      <div>
        <button
          className="emailVerifiedButton"
          onClick={() => setNavToAppStore(true)}
        >
          <div className="emailVerifiedButtonText">Ok</div>
        </button>
      </div>
    )
  }

  return renderContent()
}

export default Test
