import React from 'react'
import { Link } from 'react-router-dom'

import './termsAndConditions.css'

const TermsOfService = () => {
  return (
    <div className="termsBed">
      <div className="termsContainer">
        <div className="termsHeading">CV CLOUD</div>
        <div className="termsHeading">
          MOBILE APPLICATION / WEBSITE TERMS OF USE
        </div>
        <div className="termsText">
          CV Cloud is owned and operated by a sole proprietorship. CV Cloud has
          been developed to assist users with compiling a professional CV within
          a couple of minutes.
        </div>
        <div className="termsText">
          These Terms of Use should be read together with our Privacy Policy
          <Link to="/privacy-policy" className="privacyPolicyLink">
            <div className="privacyPolicyLinkText">View Privacy Policy</div>
          </Link>
        </div>
        <div className="termsText">1 Acceptance of these Terms of Use.</div>
        <div className="termsText">
          1.1 These Terms of Use, including our Privacy Policy and all other
          polices that may be posted on CV Cloud set out the terms on which we
          offer you access to use our mobile application and website. All of our
          policies are incorporated into these Terms of Use. Your agree to
          comply with all of our policies and in particular these Terms of Use
          when you access and use our website.
        </div>
        <div className="termsText">
          1.2 It is your responsibility to familiarise yourself with these Terms
          and check them regularly for any updates.
        </div>
        <div className="termsText">
          1.3 By accessing the website and using our services, you agree to all
          the terms set out in these Terms of Use, which are designed to make
          sure that the website is useful to everyone. Should you not agree to
          these terms, or any of our updates or changes thereto as dealt with
          below, you should not access or use website.
        </div>
        <div className="termsText">
          1.4 You confirm that you are 18 years or older, or that you have been
          duly assisted to consent to these terms.
        </div>
        <div className="termsText">2 Log-in details and passwords.</div>
        <div className="termsText">
          2.1 You must be registered to create a CV on CV Cloud. You are
          responsible for all actions taken using your username, email address
          and password.
        </div>
        <div className="termsText">
          2.2 You agree that you will use your username and password for your
          personal use only and will not disclose it to or share it with any
          unauthorised third party.
        </div>
        <div className="termsText">3 Using CV Cloud.</div>
        <div className="termsText">
          3.1 As a condition of your use of CV Cloud, you agree that you will
          not:
        </div>
        <div className="termsText">
          3.1.1 use CV Cloud in any manner that could impair our mobile
          application and/or website in any way or interfere with any party’s
          use or enjoyment of our mobile application and/or website;
        </div>
        <div className="termsText">
          3.1.2 distribute viruses or any other technologies that may harm CV
          Cloud or the interests or property of CV Cloud users;
        </div>
        <div className="termsText">
          3.1.3 impose an unreasonable load on our infrastructure or interfere
          with the proper working of CV Cloud;
        </div>
        <div className="termsText">
          3.1.4 copy, modify, or distribute any other person's content without
          their consent;
        </div>
        <div className="termsText">
          3.1.5 use any robot spider, scraper or other automated means to access
          CV Cloud and collect content for any purpose without our express
          written permission;
        </div>
        <div className="termsText">
          3.1.6 harvest or otherwise collect information about others, including
          email addresses, without their consent or otherwise violate the
          privacy of another person;
        </div>
        <div className="termsText">
          3.1.7 bypass measures used to prevent or restrict access to CV Cloud.
        </div>
        <div className="termsText">
          3.2 You are solely responsible for all information that you upload to
          CV Cloud and any resultant consequences. We reserve the right for any
          or no reason, at our discretion to refuse or delete any CV content (or
          any part thereof) that we believe is inappropriate or is in breach of
          these Terms of Use or any of our other policies. We also reserve the
          right at our discretion to restrict your use of CV Cloud either
          temporarily or permanently, or refuse a your registration.
        </div>
        <div className="termsText">4 Protecting CV Cloud.</div>
        <div className="termsText">
          4.1 CV Cloud works to keep the mobile application / website working
          properly and the community safe. Please report problems, offensive
          content and policy breaches to us at the following email address
          nicorapelas@gmail.com
        </div>
        <div className="termsText">
          4.2 Without limiting other remedies which might be available to us, we
          may issue warnings, limit or terminate our service, remove hosted
          content and take technical and legal steps to keep users off CV Cloud
          if we think that they are creating problems or acting inconsistently
          with the letter or spirit of our policies. However, whether we decide
          to take any of these steps is our decision and we do not accept any
          liability for monitoring CV Cloud or for unauthorised or unlawful
          content on CV Cloud or use of CV Cloud by users.
        </div>
        <div className="termsText">
          4.3 You also recognise and accept that CV Cloud is not under any
          obligation to monitor any data or content which is submitted to or
          available on the website.
        </div>
        <div className="termsText">5 Fees.</div>
        <div className="termsText">
          5.1 Using CV Cloud to create, email and print your CV is free.
        </div>
        <div className="termsText">
          5.2 You may incur data charges at your carrier’s applicable rates when
          using CV Cloud.
        </div>
        <div className="termsText">6 Content on CV Cloud.</div>
        <div className="termsText">
          CV Cloud may contain content from us and other businesses that may
          advertise on the mobile application and/or website from time to time.
          CV Cloud is protected by copyright laws. Content displayed on or via
          CV Cloud is protected as a collective work and/or compilation,
          pursuant to copyrights laws. You agree not to copy, distribute or
          modify content from CV Cloud without our express written consent. You
          may not disassemble or decompile, reverse engineer or otherwise
          attempt to discover any source code contained in CV Cloud. Without
          limiting the foregoing, you agree not to reproduce, copy, sell,
          resell, or exploit for any purposes any aspect of CV Cloud.
        </div>
        <div className="termsText">7 Liability.</div>
        <div className="termsText">
          7.1 CV Cloud does not act a labour broker, or otherwise. CV Cloud is
          only a platform which allows users to create, email and print their CV
          and is not otherwise involved in any users job applications process.
        </div>
        <div className="termsText">
          7.2 We do not actively monitor data or content. We are not involved in
          any interactions which may subsequently be entered into as a result of
          preparing your CV on CV Cloud.
        </div>
        <div className="termsText">
          7.3 You understand that it is a criminal offence to deliberately
          include any false information and/or information which may deceive a
          prospective employer in your CV. CV Cloud does not take any
          responsibility for the content included in your CV and it is your
          responsibility to ensure that your CV is correct, no misleading and up
          to date.
        </div>
        <div className="termsText">
          7.4 CV Cloud may contain links to third-party websites which offer
          certain goods or services. These websites, services and and/or goods
          are beyond the control of CV Cloud. CV Cloud is not involved in
          transactions between users and the operators of such third-party
          sites. CV Cloud does not accept responsibility for their content,
          services and/or products.
        </div>
        <div className="termsText">
          7.5 We cannot guarantee continuous, error-free or secure access to our
          services or that defects in the service will be corrected.
        </div>
        <div className="termsText">
          7.6 While we will use reasonable efforts to maintain an uninterrupted
          service, we cannot guarantee this and we do not give any promises or
          warranties (whether express or implied) about the availability of our
          website.
        </div>
        <div className="termsText">
          7.7 Accordingly, to the extent legally permitted we expressly disclaim
          all warranties, representations and conditions, express or implied,
          including those of quality, merchantability, merchantable quality,
          durability, fitness for a particular purpose and those arising by
          statute. We are not liable for any loss, whether of money (including
          profit), goodwill, or reputation, or any special, indirect, or
          consequential damages arising out of your use of CV Cloud, even if you
          advise us or we could reasonably foresee the possibility of any such
          damage occurring.
        </div>
        <div className="termsText">
          7.8 Nothing in these terms shall limit our liability for fraudulent
          misrepresentation, for death or personal injury resulting from our
          negligence or the negligence of our agents or employees.
        </div>
        <div className="termsText">8 Security.</div>
        <div className="termsText">
          In order to ensure the security and reliable operation of the website
          for all users, we reserve the right at our sole and absolute
          discretion to take whatever action it finds necessary to preserve the
          security, integrity and reliability of our network and back-office
          applications. Any user who commits any of the offences detailed in
          Chapter 13 of the Electronic Communications and Transactions Act, 2002
          (specifically sections 85 to 88 (inclusive)) or the Cybercrimes Act,
          2020 will, notwithstanding criminal prosecution, be liable for all
          resulting liability, loss or damages suffered and/or incurred by CV
          Cloud and its affiliates, agents and/or partners.
        </div>
        <div className="termsText">9 General.</div>
        <div className="termsText">
          9.1 These terms and the other policies posted on CV Cloud constitute
          the entire agreement between CV Cloud and you.
        </div>
        <div className="termsText">
          9.2 This agreement shall be governed by the laws of the Republic of
          South Africa. You agree that any claim or dispute you may have against
          CV Cloud must be resolved by in the courts of the Republic of South
          Africa.
        </div>
        <div className="termsText">
          9.3 If we don't enforce any particular provision, we are not waiving
          our right to do so later. If a court strikes down any of these terms,
          the remaining terms will survive.
        </div>
        <div className="termsText">
          9.4 We may update these Terms of Use at any time and in our sole
          discretion. Any such change will be effective from the date of being
          posted on the mobile application / website.
        </div>
      </div>
    </div>
  )
}

export default TermsOfService
