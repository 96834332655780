import React, { useContext, useState, useEffect } from 'react'

import { Context as AuthContext } from '../../../../context/AuthContext'
import LoaderFullScreen from '../../../bits/LoaderFullScreen'
import logo from '../../../../assets/images/logo-h65.png'
import './resetPassword.css'

const ResetPassword = (props) => {
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [password2Error, setPassword2Error] = useState(null)
  const [tokenError, setTokenError] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const { token } = props.match.params

  const {
    state: { loading, error, success },
    passwordReset,
    clearError,
  } = useContext(AuthContext)

  useEffect(() => {
    if (error) {
      if (error.password) setPasswordError(error.password)
      if (error.password2) setPassword2Error(error.password2)
      if (error.token) setTokenError(error.token)
    }
  }, [error])

  useEffect(() => {
    if (success) setSuccessMessage(success)
  }, [success])

  console.log(successMessage)

  const submitButton = () => {
    if (password === password2 && password.length > 5) {
      return (
        <div
          className="resetPasswordFormButtonBed"
          onClick={() => {
            clearError()
            passwordReset({ password, password2, token })
          }}
        >
          <div className="resetPasswordFormButton">Reset password</div>
        </div>
      )
    }
    return (
      <div className="errorsBed">
        {password !== password2 || password.length < 1 ? (
          <div className="resetPasswordFormErrorText">Passwords must match</div>
        ) : null}
        {password.length > 5 ? null : (
          <div className="resetPasswordFormErrorText">
            Password must be 6 or more characters
          </div>
        )}
      </div>
    )
  }

  const resetPasswordForm = () => {
    if (tokenError) {
      return (
        <div className="resetPasswordTokenErrorBed">
          <div className="resetPasswordTokenErrorText">{tokenError}</div>
        </div>
      )
    }
    if (successMessage) {
      return (
        <div className="resetPasswordSuccessBed">
          <div className="resetPasswordSuccessText">{successMessage}</div>
          <div className="resetPasswordSuccessText">
            You can now log into CV Cloud using your new credentials.
          </div>
        </div>
      )
    }
    return (
      <div className="resetPasswordFormBed">
        <div className="resetPasswordFormContainer">
          <div className="resetPasswordFormLogobed">
            <img
              className="resetPasswordFormLogo"
              src={logo}
              alt="cvCloud-logo"
            />
          </div>
          <div className="resetPasswordFormHeading">Reset your password.</div>
          <div className="resetPasswordFormInputBed">
            <div className="resetPasswordFormInputContainer">
              <input
                className={
                  !error
                    ? 'resetPasswordFormInput'
                    : 'resetPasswordFormInputError'
                }
                type="password"
                placeholder="Password"
                value={password}
                onFocus={() => clearError()}
                onChange={(e) => setPassword(e.target.value)}
              />
              {!passwordError ? null : (
                <div className="resetPasswordFormErrorText">
                  {passwordError}
                </div>
              )}
              <input
                className={
                  !error
                    ? 'resetPasswordFormInput'
                    : 'resetPasswordFormInputError'
                }
                type="password"
                placeholder="Confirm password"
                value={password2}
                onFocus={() => clearError()}
                onChange={(e) => setPassword2(e.target.value)}
              />
              {!password2Error ? null : (
                <div className="resetPasswordFormErrorText">
                  {password2Error}
                </div>
              )}
            </div>
          </div>
          {submitButton()}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (loading) return <LoaderFullScreen />
    return (
      <div className="resetPasswordBed">
        <div className="resetPasswordContainer">{resetPasswordForm()}</div>
      </div>
    )
  }

  return renderContent()
}

export default ResetPassword
