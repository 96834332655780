import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as circleFill } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/fontawesome-free-regular'

import './proficiency.css'

const ProficiencyOne = ({ proficiencyColor }) => {
  return (
    <div>
      <FontAwesomeIcon icon={circleFill} className={proficiencyColor} />
      <FontAwesomeIcon icon={faCircle} className={proficiencyColor} />
      <FontAwesomeIcon icon={faCircle} className={proficiencyColor} />
      <FontAwesomeIcon icon={faCircle} className={proficiencyColor} />
      <FontAwesomeIcon icon={faCircle} className={proficiencyColor} />
    </div>
  )
}

export default ProficiencyOne
