import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { faCertificate } from '@fortawesome/free-solid-svg-icons'

import { Context as UniversalContext } from '../../context/UniversalContext'
import './attachedCertificateButton.css'

const AttachedCertificateButton = ({ certificate }) => {
  const {
    setShowMenuModal,
    setMenuModalBit,
    setMediaModalBit,
    setHideHeaderAndFooter,
  } = useContext(UniversalContext)

  const renderContent = () => {
    if (!certificate || certificate.length === 0) return null
    return (
      <div className="attachedCertificateButtonBed">
        <div
          className="attachedCertificateButton"
          onClick={() => {
            setMenuModalBit('certificate')
            setMediaModalBit('certificate')
            setHideHeaderAndFooter(true)
            setShowMenuModal(true)
          }}
          title="view attached certificates"
        >
          <FontAwesomeIcon
            icon={faPaperclip}
            className="attachedCertificateButtonClipIcon"
          />
          <div className="attachedCertificateButtonText">
            {certificate.length} certificates attached
          </div>
          <FontAwesomeIcon
            icon={faCertificate}
            className="attachedCertificateButtonIcon"
          />
        </div>
      </div>
    )
  }

  return renderContent()
}

export default AttachedCertificateButton
