import React from 'react'

import './loaderFullScreen.css'

const LoaderFullScreen = () => {
  return (
    <div className="loaderFullScreenBed">
      <div className="loaderFullScreenContainer">
        <div className="ui active inverted inline loader" />
        <div className="loaderFullScreenText">Loading</div>
      </div>
    </div>
  )
}

export default LoaderFullScreen
