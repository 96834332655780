import React from 'react'

import './cvCloud.css'

const Experience = ({ experience }) => {
  const renderContent = () => {
    if (!experience || experience.length < 1) return null
    return (
      <>
        <div className="cvCloudRightBitBed">
          <div className="cvCloudRightHeading">Experience</div>
          {experience.map((exp) => {
            return (
              <div
                className={
                  experience.length > 1
                    ? 'cvCloudRightFieldsTwoBed'
                    : 'cvCloudRightFieldsBed'
                }
                key={exp._id}
              >
                <div className="cvCloudRightFieldBed">
                  <div className="cvCloudRightFieldTitle">{exp.title}</div>
                </div>
                {!exp.description || exp.description.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <div className="cvCloudRightFieldText">
                      {exp.description}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <hr className="cvCloudRightHr" />
      </>
    )
  }

  return renderContent()
}

export default Experience
