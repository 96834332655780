import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faBriefcase,
  faCalendarAlt,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'

import './cvCloud.css'

const EmployHistory = ({ employHistory }) => {
  const renderContent = () => {
    if (!employHistory || employHistory.length < 1) return null
    return (
      <>
        <div className="cvCloudRightBitBed">
          <div className="cvCloudRightHeading">Employment history</div>
          {employHistory.map((emp) => {
            return (
              <div
                className={
                  employHistory.length > 1
                    ? 'cvCloudRightFieldsTwoBed'
                    : 'cvCloudRightFieldsBed'
                }
                key={emp._id}
              >
                <div className="cvCloudRightFieldBed">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className="cvCloudRightFieldIcon"
                  />
                  <div className="cvCloudRightFieldText">{emp.company}</div>
                </div>
                {!emp.position || emp.position.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="cvCloudRightFieldText">{emp.position}</div>
                  </div>
                )}
                {!emp.startDate || emp.startDate.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="cvCloudRightFieldText">
                      {emp.startDate}
                      {!emp.endDate ? null : ` - ${emp.endDate}`}
                    </div>
                  </div>
                )}
                {!emp.description || emp.description.length < 1 ? null : (
                  <div className="cvCloudRightFieldBed">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="cvCloudRightFieldIcon"
                    />
                    <div className="cvCloudRightFieldText">
                      {emp.description}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <hr className="cvCloudRightHr" />
      </>
    )
  }

  return renderContent()
}

export default EmployHistory
