import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import history from '../../../history'
import { Context as ModalContext } from '../../../context/ModalContext'
import './burgerMenuModal.css'

const BurgerMenuModal = () => {
  const {
    state: { burgerMenuModalVisible },
    setBurgerMenuModalVisible,
  } = useContext(ModalContext)

  if (!burgerMenuModalVisible) return null

  const renderContent = () => {
    return (
      <>
        <div
          className="burgerMenuModalLink"
          onClick={() => {
            setBurgerMenuModalVisible(false)
            history.push('/terms-of-service')
          }}
        >
          Terms of service
        </div>
        <div
          className="burgerMenuModalLink"
          onClick={() => {
            setBurgerMenuModalVisible(false)
            history.push('/privacy-policy')
          }}
        >
          Privacy policy
        </div>
        <div
          className="burgerMenuModalLink"
          onClick={() => {
            setBurgerMenuModalVisible(false)
            history.push('/exiting-instructions')
          }}
        >
          Exiting instructions
        </div>
      </>
    )
  }

  return ReactDOM.createPortal(
    <div className="ui dimmer active">
      <div className="burgerMenuModalViewCvBed">
        <div className="burgerMenuModalCloseButtonBed">
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="burgerMenuModalCloseButton"
            onClick={() => {
              setBurgerMenuModalVisible(false)
            }}
          />
        </div>
        {renderContent()}
      </div>
    </div>,
    document.querySelector('#modal')
  )
}

export default BurgerMenuModal
