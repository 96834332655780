import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faPhone, faMapSigns } from '@fortawesome/free-solid-svg-icons'

import './cvCloud.css'

const ContactInfo = ({ contactInfo }) => {
  const renderContent = () => {
    if (!contactInfo || contactInfo.length < 1) return null
    const {
      email,
      phone,
      address,
      suburb,
      city,
      province,
      country,
      postalCode,
    } = contactInfo
    return (
      <>
        <div className="cvCloudLeftBitBed">
          <div className="cvCloudLeftBitContainer">
            <div className="cvCloudLeftHeading">Contact details</div>
            {!email || email.length < 1 ? null : (
              <div className="cvCloudLeftFieldBed">
                <FontAwesomeIcon icon={faAt} className="cvCloudLeftFieldIcon" />
                <div className="cvCloudLeftFieldTextBreakWord">{email}</div>
              </div>
            )}
            {!phone || phone.length < 1 ? null : (
              <div className="cvCloudLeftFieldBed">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="cvCloudLeftFieldIcon"
                />
                <div className="cvCloudLeftFieldTextBreakWord">{phone}</div>
              </div>
            )}
            {!address &&
            !suburb &&
            !city &&
            !province &&
            !country &&
            !postalCode ? null : (
              <div className="cvCloudLeftFieldBed">
                <FontAwesomeIcon
                  icon={faMapSigns}
                  className="cvCloudLeftFieldIcon"
                />
                <div className="cvCloudLeftAddressContainer">
                  {!address || address.length < 1 ? null : (
                    <div className="cvCloudLeftFieldText">{address}</div>
                  )}
                  {!suburb || suburb.length < 1 ? null : (
                    <div className="cvCloudLeftFieldText">{suburb}</div>
                  )}
                  {!city || city.length < 1 ? null : (
                    <div className="cvCloudLeftFieldText">{city}</div>
                  )}
                  {!province || province.length < 1 ? null : (
                    <div className="cvCloudLeftFieldText">{province}</div>
                  )}
                  {!country || country.length < 1 ? null : (
                    <div className="cvCloudLeftFieldText">{country}</div>
                  )}
                  {!postalCode || postalCode.length < 1 ? null : (
                    <div className="cvCloudLeftFieldText">{postalCode}</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <hr className="cvCloudLeftHr" />
      </>
    )
  }

  return renderContent()
}

export default ContactInfo
